import { Injectable, NgModule } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterModule, RouterStateSnapshot, TitleStrategy } from '@angular/router';

import { AdminRoute, AppRoutes, Divider } from './app-routes';

@Injectable()
export class CustomTitleStrategy extends TitleStrategy {
  baseTitle: string = 'NB Medical Admin';

  constructor(private readonly title: Title) {
    super();
  }

  override updateTitle(routerState: RouterStateSnapshot): void {
    const title = this.buildTitle(routerState);
    if (title !== undefined) {
      this.title.setTitle(`${title} - ${this.baseTitle}`);
    } else {
      this.title.setTitle(`${this.baseTitle}`);
    }
  }
}

@NgModule({
  imports: [
    RouterModule.forRoot(
      AppRoutes.filter((x) => !(x as Divider).dividerName).map(
        (x) => x as AdminRoute
      ),
      {}
    ),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
