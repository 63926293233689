import { Component, HostBinding, Input, TemplateRef } from '@angular/core';

import { ToastService } from '../toast.service';

@Component({
  selector: 'nba-toast-display',
  templateUrl: './toast-display.component.html',
  styleUrls: ['./toast-display.component.scss'],
})
export class ToastDisplayComponent {
  constructor(public toastService: ToastService) {}
  @HostBinding('class.ngb-toasts') toasts = true;
  autohide = true;

  @Input()
  delay = 5000;

  isTemplate(toast) {
    return toast.textOrTpl instanceof TemplateRef;
  }
}
