import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';

import { ToastsContainerComponent } from './toast-container-component';
import { ToastDisplayComponent } from './toast-display/toast-display.component';

@NgModule({
  declarations: [ToastsContainerComponent, ToastDisplayComponent],
  imports: [CommonModule, NgbToastModule],
  exports: [ToastsContainerComponent],
})
export class ToastsModule {}
