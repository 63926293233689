import { Component } from '@angular/core';

@Component({
  selector: 'nba-toasts',
  template: `
    <div
      class="ngb-toasts toast-container position-fixed top-0 end-0 p-3"
      style="z-index: 1200;"
    >
      <nba-toast-display> </nba-toast-display>
    </div>
  `,
})
export class ToastsContainerComponent {}
