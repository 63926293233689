<ngb-toast
  *ngFor="let toast of toastService.toasts"
  [class]="toast.classname"
  [autohide]="autohide"
  (mouseenter)="autohide = false"
  (mouseleave)="autohide = true"
  (hidden)="autohide = true; toastService.remove(toast)"
  [delay]="toast.delay || 5000"
>
  <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
    <ng-template [ngTemplateOutlet]="toast.textOrTpl"></ng-template>
  </ng-template>

  <ng-template #text>{{ toast.textOrTpl }}</ng-template>
</ngb-toast>
